import { createApp } from 'vue';
import App from './App.vue';
import Emitter from 'tiny-emitter';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import { jwtInterceptor } from './jwt.interceptor';

jwtInterceptor();

const app = createApp(App);
app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = new Emitter();

app.use(store).use(ElementPlus).mount('#app');
