import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      msalConfig: {
        auth: {
          clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
          authority: process.env.VUE_APP_AZURE_AUTHORITY_URL + process.env.VUE_APP_AZURE_TENAT_ID,
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      },
    };
  },
  getters: {
    msalConfig: state => state.msalConfig
  },
});

export default store;