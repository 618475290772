<template>
  <div>
  <div v-if="account">
    <el-row justify="end">
      <el-button class="el-button--save-all" :type="!changedData.length ? 'info' : 'danger'" 
                 @click="savaAllChanges" :disabled="!changedData.length">
        <el-icon class="el-icon--left"><Check /></el-icon>Save all ( {{ changedData.length }} )
      </el-button>
    </el-row>

      <el-table class="main-table" v-loading="loading" :data="pagedTableData" flexible scrollbar-always-on height="700" border>
      <el-table-column label="PG organization" min-width="150">
        <template #default="scope">
          <a target="_blank" :href = "scope.row.organization_url">{{ scope.row.organization_name }}</a>
        </template>
      </el-table-column>
      <el-table-column label="PG application" min-width="150">
        <template #default="scope">
          <a target="_blank" :href = "scope.row.project_url">{{ scope.row.project_name }}</a>
        </template>
      </el-table-column>
      <el-table-column label="PG repository" min-width="150">
        <template #default="scope">
          <a target="_blank" :href = "scope.row.commit_url">{{ scope.row.repository_name }}</a>
        </template>
      </el-table-column>
      <el-table-column label="Commit date" width="150">
        <template #default="scope">{{ formatDate(scope.row.date) }}</template>
      </el-table-column>
      <el-table-column label="SULU Project" min-width="340">
        <template #default="scope">
          <el-select v-model="scope.row.sulu_proj_id" value-key="scope.row.project_info" fit-input-width filterable placeholder="Select" style="width: 100%" @change="handleChange(scope.$index, scope.row)">
            <el-option
              v-for="item in projects"
              :key="item.sulu_proj_id"
              :label="item.project_info"
              :value="item.sulu_proj_id">
              <span :title=item.project_info>{{ item.project_info }}</span>
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Technical Leader" width="260">
        <template #default="scope">
          <el-select v-model="scope.row.tech_lead" fit-input-width style="width: 100%" filterable placeholder="Select" @change="handleChange(scope.$index, scope.row)">
            <el-option
              v-for="item in techLeads"
              :key="item.tech_lead"
              :label="item.tech_lead"
              :value="item.tech_lead"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="Comment" min-width="150">
        <template #default="scope">
          <el-input v-model="scope.row.mapping_comment" placeholder="Comment..." clearable @input="handleChange(scope.$index, scope.row)"/>
        </template>
      </el-table-column>
      <el-table-column label="Operations" width="95">
        <template #default="scope">
          <el-button :type="!scope.row.isChanged ? 'info' : 'danger' " :disabled="!scope.row.isChanged" @click="saveCommit(scope.row)">Save</el-button>
        </template>
      </el-table-column>
    </el-table>
      
    <el-pagination
        small
        layout="prev, pager, next"
        :total="this.commits.length"
        hide-on-single-page
        :page-size="this.pageSize"
        @current-change="setPage">
    </el-pagination>
  </div>
  <p class="auth-info" v-else>You need to authenticate to access your data</p>
  </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { Check } from '@element-plus/icons-vue';
 
export default {
  name: 'CommitList',
  data() {
    return {
      account: undefined,
      commits: [],
      techLeads: [],
      projects: [],
      page: 1,
      pageSize: 20,
      loading: false,
    };
  },

  components: {
    Check,
  },

  computed: {
    pagedTableData() {
      return this.commits.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
    },
    changedData() {
      return this.commits.filter(e => e.isChanged);
    }
  },

  created() {
    this.$emitter.on(
      'login', function (account) {
        this.account = account;
        this.getProjects();
        this.getTechLeads();
      }.bind(this),
    ),
    this.$emitter.on('logout', function () {
        console.log('logging out');
        this.account = undefined;
      }.bind(this)
    );
  },
 
  methods: {
    handleChange (index, row) {
      row.isChanged = true;
    },

    setPage (val) {
      this.page = val
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('default', {dateStyle: 'short', timeStyle: 'short'}).format(date);
    },

    savaAllChanges () {
      this.commits.forEach(e => {
        if (e.isChanged) {
          this.saveCommit(e);
        }
      });
    },

    showMessage(type, message) {
      ElMessage({
        title: type.toUpperCase(),
        message: message,
        type: type,
        grouping: true
      })
    },

    async getCommits() {
      this.loading = true;
      try {
        const response = await axios.post(process.env.VUE_APP_API_URL + "filteredCommits", {
          email: this.account.username
        });

        this.commits = response.data;
        this.commits.forEach(e => {
          if (e.sulu_proj_id) {
            const suluProject = this.projects.filter(obj => obj.sulu_proj_id == e.sulu_proj_id);
            e.sulu_proj_oldId = suluProject[0].sulu_proj_id;
            e.sulu_proj_id = suluProject[0].project_info;
          }
        })
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.showMessage('error', 'Error while attempting to load the datasource.');
        this.loading = false;
      }
    },

    async getProjects() {
      this.loading = true;
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + "projects");
        this.projects = response.data;
        this.loading = false;
        this.getCommits();
      } catch (err) {
        console.log(err);
        this.showMessage('error', 'Error while attempting to load the datasource.');
        this.loading = false;
      }
    },

    async getTechLeads() {
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + "techleads");
        this.techLeads = response.data;
      } catch (err) {
        console.log(err);
        this.showMessage('error', 'Error while attempting to load the datasource.');
      }
    },

    async saveCommit(item) {    
      if (!item.sulu_proj_id) {
        return this.showMessage('warning', 'Select SULU Project!');
      }

      if (!item.tech_lead) {
        return this.showMessage('warning', 'Select TechLead!');
      }

      try {
        await axios.post(process.env.VUE_APP_API_URL + "mapcommits", {
          commit_id: item.commit_id,
          sulu_proj_id: typeof item.sulu_proj_id === 'number' ? item.sulu_proj_id : item.sulu_proj_oldId,
          tech_lead: item.tech_lead,
          comment: item.mapping_comment ? item.mapping_comment : null
        });
        item.isChanged = false;
        this.showMessage('success', 'Successfully Saved');
      } catch (err) {
        this.showMessage('error', 'Something Went Wrong. Try again');
      }
    },
  },
};
</script>