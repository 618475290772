<template>
  <el-container>
    <el-header>
      <h1>Mapping of PG repositories to SULU projects</h1>
      <img class="logo" alt="Vue logo" src="./assets/logo.png"/>
    </el-header>
    <el-main>
      <CommitList />
      <AuthBlock/>
    </el-main>
  </el-container>
</template>

<script>
import AuthBlock from './components/AuthBlock.vue'
import CommitList from './components/CommitList.vue'

export default {
  name: 'App',
  components: {
    AuthBlock,
    CommitList
  }
}
</script>

<style src="./assets/styles.css" />