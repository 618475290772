import axios from 'axios';
import { PublicClientApplication } from '@azure/msal-browser';
import store from './store';

export function jwtInterceptor() {

  axios.interceptors.request.use(async request => {
    const msalInstance = new PublicClientApplication(
      store.getters.msalConfig
    );

    const myAccounts = msalInstance.getAllAccounts();
    let account = myAccounts[0];
    const accessTokenRequest = {
      account: account,
      scopes: ["email openid profile"]
    };

    if(Math.floor(Date.now() / 1000) > account.idTokenClaims.exp) {
      await msalInstance.acquireTokenSilent(accessTokenRequest)
        .then(function (accessTokenResponse) {
          window.localStorage.setItem('idToken', accessTokenResponse.idToken);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    const token = window.localStorage.getItem('idToken');
    request.headers.common.Authorization = `Bearer ${token}`;
    return request;
  });
}