<template>
  <div :class="{ 'text-center': !account }">
    <p v-if="account">You are logged in as <b>{{ account.name }}</b></p>
    <el-button v-if="!account" type="primary" @click="SignIn">Sign In</el-button>
    <el-button v-else size="small" plain @click="SignOut">Sign Out</el-button> 
  </div>
</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';

export default {
  name: 'AuthBlock',
  data() {
    return {
      account: undefined,
      signin: process.env.VUE_APP_AZURE_SIGNIN_URL,
    };
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
  },
  mounted() {
    const accounts = this.$msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      return;
    }
    this.account = accounts[0];
    this.$emitter.emit('login', this.account);
  },
  methods: {
    async SignIn() {
      await this.$msalInstance
        .loginPopup({})
        .then((response) => {
          const myAccounts = this.$msalInstance.getAllAccounts();
          this.account = myAccounts[0];
          window.localStorage.setItem('idToken', response.idToken);
          this.$emitter.emit('login', this.account);
        })
        .catch(error => {
          console.error(`error during authentication: ${error}`);
        });
    },
    async SignOut() {
      window.localStorage.removeItem('idToken');
      await this.$msalInstance
        .logout({})
        .then(() => {
          this.$emitter.emit('logout', 'logging out');
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>